@font-face {
    font-family: 'Inter';
    src: url('../../assets/fonts/Inter/Inter.ttf');
}

@font-face {
    font-family: 'Inter-Medium';
    src: url('../../assets/fonts/Inter/Inter-Medium.ttf');
    font-style: normal;
    font-weight: 500;
}

body {
    margin: 0;
    font-family: Inter, serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    .circle-widget-trigger {
        display: none;
    }
    .circle-widget-overlay {
        a.button-close {
            cursor: initial;
            width: calc(100vw - 73px);
            max-width: 1304px;
            justify-content: flex-end;
            right: unset;
            left: unset;
            top: 18px;
            height: unset;
            div {
                cursor: pointer;
                background: white;
                height: 32px;
                width: 32px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    content: url('../../assets/images/icons/ico-close.svg');
                }
            }
        }
    }
}

code {
    font-family: 'Inter', monospace;
}

#app-container {
    background-color: #ffffff;
}
//This makes sure that we don't have those white lines on the side, when we have scrolling overflow in some cases
html {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #ffffff;
}
